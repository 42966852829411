import React from 'react';

import {
  DSL_RelatedRecord,
  DSL_RestrictedVisualProps,
} from 'components/DesignSystem/Library/types';

import {
  DSL_SingleSelectAutocompleteInput,
  DSL_SingleSelectAutocompleteInputProps,
} from '../SingleSelectAutocompleteInput';

export type DSL_SearchAutocompleteInputProps = Omit<
  DSL_SingleSelectAutocompleteInputProps<DSL_RelatedRecord>,
  DSL_RestrictedVisualProps
>;

export const DSL_SearchAutocompleteInput = ({
  options = [],
  inputValue,
  loading,
  id,
  focused,
  ...props
}: DSL_SearchAutocompleteInputProps) => {
  return (
    <DSL_SingleSelectAutocompleteInput<DSL_RelatedRecord>
      id={id}
      clearOnBlur={false}
      includeInputInList
      selectOnFocus
      inputValue={inputValue}
      options={options}
      loading={loading}
      focused={focused}
      {...props}
    />
  );
};

DSL_SearchAutocompleteInput.displayName = 'DSL_SearchAutocompleteInput';

export {
  //
  /** @deprecated use DSL_SearchAutocompleteInputProps instead*/
  DSL_SearchAutocompleteInputProps as SearchAutocompleteInputProps, //
  /** @deprecated use DSL_SearchAutocompleteInput instead*/
  DSL_SearchAutocompleteInput as SearchAutocompleteInput,
};
