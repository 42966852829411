import React from 'react';

import {
  DSL_BaseInputProps,
  RichTextInput,
} from 'components/DesignSystem/Library';

import {DSP_UseRichTextFieldArgs, useRichTextField} from './useRichTextField';

export type DSP_RichTextFieldProps = DSP_UseRichTextFieldArgs &
  Pick<DSL_BaseInputProps, 'size'>;

export const DSP_RichTextField = (props: DSP_RichTextFieldProps) => {
  const fieldProps = useRichTextField(props);
  return <RichTextInput {...fieldProps} />;
};

export {
  //
  /** @deprecated use DSP_RichTextFieldProps instead*/
  DSP_RichTextFieldProps as RichTextFieldProps, //
  /** @deprecated use DSP_RichTextField instead*/
  DSP_RichTextField as RichTextField,
};
