import axios from 'axios';
import {
  getMapBoxSessionToken,
  getMapboxKey,
  mapBoxSuggestionSearchUrl,
} from 'utils/global';

import {
  MapBoxSuggestedResultsSearchRequest,
  MapBoxSuggestedResultsSearchResponse,
  SuggestedResultsOptions,
} from './types';

export const getSuggestedResults = (
  search: string,
  userId: number,
  optionOverrides: SuggestedResultsOptions = {},
) => {
  return axios.get<MapBoxSuggestedResultsSearchResponse>(
    mapBoxSuggestionSearchUrl,
    {
      params: {
        country: 'au',
        types: 'address',
        limit: 5,
        language: 'en',
        q: search,
        session_token: getMapBoxSessionToken(userId),
        access_token: getMapboxKey(),
        ...optionOverrides,
      } as MapBoxSuggestedResultsSearchRequest,
    },
  );
};
