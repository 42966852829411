import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/accredited-auditors',
  component: lazy(
    () => import(/* webpackChunkName: "AuditorsPublic" */ './AuditorsPublic'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Accredited Auditors',
  menuIcon: Today,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
