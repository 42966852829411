import {lazy} from 'react';
import Assignment from '@mui/icons-material/Assignment';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {RouteConfig} from 'app/router/types';

export default {
  path: '/:accreditationId',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationLanding" */ './AccreditationLanding'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Accreditation Management',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.findById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation Library',
      to: '..',
    },
  ],
} as RouteConfig;
