import React from 'react';

import {onGenerateSASTokenFunc} from 'utils/stores/types';

import {
  DSL_BaseInputProps,
  DSL_FileUploadInput,
} from 'components/DesignSystem/Library';

import {DSP_GetGenericFieldProps} from '../utils/getGenericFieldProps';

import {DSP_DeleteFileDialog, useDeleteFileDialog} from './DeleteFileDialog';
import {DSP_EditFileDialog, useEditFileDialog} from './EditFileDialog';
import {useAttachmentsField} from './useAttachmentsField';

type AttachmentsFieldProps = {
  prefix: string;
  fieldRef?: string;
  fileGroupId?: string;
  filesLimit?: number;
  maxFileSize?: number;
  onGenerateSASToken: onGenerateSASTokenFunc;
  accountId?: boolean;
  enableFileEdit?: boolean;
};

export type DSP_AttachmentsFieldProps = AttachmentsFieldProps &
  Omit<DSP_GetGenericFieldProps, 'fieldRef' | 'state'> &
  Pick<DSL_BaseInputProps, 'placeholder' | 'size'>;

export const DSP_AttachmentsField: React.FC<DSP_AttachmentsFieldProps> = ({
  fieldRef = '#/fileList',
  filesLimit = 100,
  maxFileSize = 20971520,
  enableFileEdit = true,
  prefix,
  accountId,
  fileGroupId,
  onGenerateSASToken,
}: DSP_AttachmentsFieldProps) => {
  const {
    onDropAccepted,
    onDropRejected,
    accept,
    fileList,
    attachmentConfig,
    activeRecord,
    fileChangeList,
    blobUrl,
  } = useAttachmentsField({
    prefix,
    fieldRef,
    accountId,
    filesLimit,
    maxFileSize,
    fileGroupId,
    onGenerateSASToken,
  });

  const {
    handleEditDialogOpen,
    handleEditDialogClose,
    setEditDialogField,
    editDialogState,
    editDialogOpen,
    onSubmit,
  } = useEditFileDialog({
    fieldRef,
    fileList,
    fileGroupId,
    activeRecord,
    attachmentConfig,
    onGenerateSASToken,
  });

  const {
    deleteDialogState,
    handleDeleteDialogClose,
    handleDeleteDialogOpen,
    onDeleteDialogSubmit,
    deleteDialogOpen,
  } = useDeleteFileDialog({fileChangeList, fieldRef});

  return (
    <>
      <DSL_FileUploadInput
        acceptedMimeTypes={accept}
        onFileEdit={enableFileEdit ? handleEditDialogOpen : undefined}
        onFileRemove={handleDeleteDialogOpen}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        blobUrl={blobUrl}
        maxSize={maxFileSize}
        filesList={fileList}
      />
      <DSP_EditFileDialog
        handleEditDialogClose={handleEditDialogClose}
        setEditDialogField={setEditDialogField}
        editDialogState={editDialogState}
        editDialogOpen={editDialogOpen}
        attachmentConfig={attachmentConfig}
        onSubmit={onSubmit}
      />
      <DSP_DeleteFileDialog
        deleteDialogOpen={deleteDialogOpen}
        deleteDialogState={deleteDialogState}
        handleDeleteDialogClose={handleDeleteDialogClose}
        onDeleteDialogSubmit={onDeleteDialogSubmit}
      />
    </>
  );
};

DSP_AttachmentsField.displayName = 'DSP_AttachmentsField';

export {
  //
  /** @deprecated use DSP_AttachmentsFieldProps instead*/
  DSP_AttachmentsFieldProps as AttachmentsFieldProps, //
  /** @deprecated use DSP_AttachmentsField instead*/
  DSP_AttachmentsField as AttachmentsField,
};
