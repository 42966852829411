import {useCallback, useMemo} from 'react';

export const getHashParams = (href = window.location.href) => {
  const url = new URL(href);
  return new URLSearchParams(url.hash.slice(1));
};

export const useHashParams = (): [
  URLSearchParams,
  (params: URLSearchParams) => void,
] => {
  const href = window.location.href;
  const hashParams = useMemo(() => getHashParams(href), [href]);

  const setHashParams = useCallback(
    (params: URLSearchParams) => {
      hashParams.forEach(searchParam => hashParams.delete(searchParam));
      params.forEach(param => {
        const value = params.get(param);

        if (value !== null) {
          hashParams.set(param, value);
        }
      });
    },
    [hashParams],
  );

  return [hashParams, setHashParams];
};
