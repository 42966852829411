import {DSP_AccreditationActionNotificationItem} from '../types';

export interface DSP_UseBaseAccreditationActionNotificationCardProps {
  notification: DSP_AccreditationActionNotificationItem;
}

export function useBaseAccreditationActionNotificationCard({
  notification,
}: DSP_UseBaseAccreditationActionNotificationCardProps) {
  const {id, accreditationNumber, applicationType} = notification.data;

  return {
    actionDescription: 'Accreditation Application is overdue',
    isRead: notification.readByLoggedInUser,
    href: `/accreditation/${id}`,
    created: notification.created ?? undefined,
    name: applicationType ?? 'Accreditation',
    applicationId: accreditationNumber ?? '',
    status: 'Overdue',
  };
}
