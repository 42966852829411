import {AssignmentOutlined} from '@mui/icons-material';
import {AccreditationRoutes} from 'containers-accreditation/index';
import AuditorRoutes from 'containers-accreditation/Auditors';
import ManageAccreditationRoutes from 'containers-accreditation/Manage';
import Auditors from './Auditors';
import Applications from './Applications';
import LandingSwitch from './LandingSwitch';
import content from 'containers-accreditation/content';

export default {
  content,
  routes: [
    LandingSwitch,
    Applications,
    Auditors,
    ...ManageAccreditationRoutes,
    ...AccreditationRoutes,
    ...AuditorRoutes,
  ],
  menuTitle: 'National Heavy Vehicle Accreditation Scheme',
  menuDescription: 'National Heavy Vehicle Accreditation Scheme',
  path: '/accreditation-nhvas',
  showChildRoutesInMenu: true,
  menuIcon: AssignmentOutlined,
};
