import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/:accreditationId/orders',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationOrders" */ './AccreditationOrders'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Orders',
  menuIcon: Today,
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.findById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation Management',
      to: '..',
    },
  ],
};
