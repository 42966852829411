import {lazy} from 'react';
import {RouteConfig} from 'app/router/types';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import Assignment from '@mui/icons-material/Assignment';

export default {
  path: '/applications/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAccreditationApplication" */ '../../../Applications/Application/AccreditationApplication'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Application',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.findOperations',
    policies: [{role: 'operationsAccreditationRead'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Applications',
      to: '..',
    },
  ],
} as RouteConfig;
