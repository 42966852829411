import React from 'react';

import {DSL_OutlinedButton} from 'components/DesignSystem/Library/Buttons/OutlinedButton';
import {DSL_Body2} from 'components/DesignSystem/Library/Typography/Body2';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_Box} from '../../Box';
import {DSL_FlexRow} from '../../FlexRow';
import {DSL_Dialog, DSL_DialogProps} from '../Dialog';
import {DSL_DialogActions} from '../DialogActions';
import {DSL_DialogContent} from '../DialogContent';
import {DSL_DialogTitle} from '../DialogTitle';

export interface DSL_WithNoteDialogProps
  extends Omit<DSL_DialogProps, DSL_RestrictedVisualProps | 'title'> {
  onClose?: () => void;
  isLoading?: boolean;
  title?: React.ReactNode;
  closeLabel?: string;
  note?: React.ReactNode;
}

export const DSL_WithNoteDialog = ({
  note,
  title,
  onClose,
  children,
  isLoading,
  closeLabel = 'Close',
  ...props
}: DSL_WithNoteDialogProps) => {
  return (
    <DSL_Dialog
      aria-label={'with-note-dialog'}
      aria-labelledby="with-note-dialog-title"
      aria-describedby="with-note-dialog-content"
      {...props}
    >
      <DSL_DialogTitle
        id="with-note-dialog-title"
        title={title}
        onClose={onClose}
        paddingBottom={0}
      />
      <DSL_DialogContent id="with-note-dialog-content">
        {children}
      </DSL_DialogContent>
      <DSL_DialogActions>
        <DSL_FlexRow justifyContent="space-between" flexWrap={'nowrap'} pb={1}>
          <DSL_FlexRow gap={1}>
            {note && (
              <DSL_Box display={'inline'}>
                <DSL_Body2 display={'inline'} bold>{`Note: `}</DSL_Body2>
                <DSL_Body2 display={'inline'}>{note}</DSL_Body2>
              </DSL_Box>
            )}
          </DSL_FlexRow>
          <DSL_FlexRow>
            <DSL_OutlinedButton
              onClick={onClose}
              loading={isLoading}
              disabled={isLoading}
            >
              {closeLabel}
            </DSL_OutlinedButton>
          </DSL_FlexRow>
        </DSL_FlexRow>
      </DSL_DialogActions>
    </DSL_Dialog>
  );
};

DSL_WithNoteDialog.displayName = 'DSL_WithNoteDialog';
