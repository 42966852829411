import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import CreateAccreditationScheme from './CreateAccreditationScheme';
import AccreditationScheme from './AccreditationScheme';

const AccreditationSchemes = {
  path: '/accreditationSchemes',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationSchemes" */ './AccreditationSchemes'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Accreditation Schemes',
  menuDescription:
    'Tools to define the heavy vehicle access details of road segments',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'AccreditationSchemeWithRelations.findById',
    policies: [
      {
        role: 'operationsAccreditationSupervisor',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};

export default AccreditationSchemes;

export const AccreditationSchemesRoutes = [
  AccreditationSchemes,
  CreateAccreditationScheme,
  AccreditationScheme,
];
