import {lazy} from 'react';
import Assignment from '@mui/icons-material/Assignment';
import {
  AuthorisationDomains,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils';
import {RouteConfig} from 'app/router/types';

export default {
  path: '/',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationLandingSwitch" */ 'containers-accreditation/LandingSwitch/AccreditationLandingSwitch'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Accreditation Management',
  menuIcon: Assignment,
  authorisation: {
    domain: AuthorisationDomains.NHVR,
    apiKey: 'AccreditationSchemeWithRelations.findById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
} as RouteConfig;
