import React from 'react';

import {DSP_AddressSearch, DSP_AddressSearchProps} from '../AddressSearch';
import {
  DSP_AttachmentsField,
  DSP_AttachmentsFieldProps,
} from '../AttachmentsField';
import {DSP_CheckboxField, DSP_CheckboxFieldProps} from '../CheckboxField';
import {DSP_DateField, DSP_DateFieldProps} from '../DateField';
import {
  DSP_MultiSelectField,
  DSP_MultiSelectFieldProps,
} from '../MultiSelectField';
import {DSP_NumberField, DSP_NumberFieldProps} from '../NumberField';
import {DSP_RichTextField, DSP_RichTextFieldProps} from '../RichTextField';
import {
  DSP_SingleSelectField,
  DSP_SingleSelectFieldProps,
} from '../SingleSelectField';
import {DSP_TextField, DSP_TextFieldProps} from '../TextField';

import {DSP_UseFormFieldProps, useFormField} from './useFormField';

export type DSP_FormFieldProps = DSP_UseFormFieldProps;

export const DSP_FormField = ({
  hideLabel,
  fieldRef,
  placeholder,
  label,
  fieldTypeOverride,
  ...restProps
}: DSP_FormFieldProps) => {
  const {fieldType, fieldProps} = useFormField({
    hideLabel,
    fieldRef,
    placeholder,
    label,
    fieldTypeOverride,
    ...restProps,
  });

  switch (fieldType) {
    case 'number':
    case 'integer':
      return <DSP_NumberField {...(fieldProps as DSP_NumberFieldProps)} />;
    case 'string':
      return <DSP_TextField {...(fieldProps as DSP_TextFieldProps)} />;
    case 'dateTime':
      return <DSP_DateField {...(fieldProps as DSP_DateFieldProps)} />;
    case 'boolean':
      return <DSP_CheckboxField {...(fieldProps as DSP_CheckboxFieldProps)} />;
    case 'selectSingle':
      return (
        <DSP_SingleSelectField
          {...(fieldProps as DSP_SingleSelectFieldProps)}
        />
      );
    case 'selectMulti':
      return (
        <DSP_MultiSelectField {...(fieldProps as DSP_MultiSelectFieldProps)} />
      );
    case 'attachment':
      return (
        <DSP_AttachmentsField {...(fieldProps as DSP_AttachmentsFieldProps)} />
      );
    case 'notes':
      return <DSP_RichTextField {...(fieldProps as DSP_RichTextFieldProps)} />;
    case 'address':
      return <DSP_AddressSearch {...(fieldProps as DSP_AddressSearchProps)} />;
    case 'notApplicable':
      return <>-</>;
    default:
      return null;
  }
};

DSP_FormField.displayName = 'DSP_FormField';

export {
  /** @deprecated use DSP_FormField instead*/
  DSP_FormField as FormField,
};
