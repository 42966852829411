import {LicenseInfo} from '@mui/x-license-pro';
import React from 'react';
import {render} from 'react-dom';
import {getMuiDataGridKey} from 'utils/global';
import {App} from './app';
import {HistoryRouter} from './app/router';
// import {registerServiceWorker} from './app/serviceWorker';

LicenseInfo.setLicenseKey(getMuiDataGridKey());

const root = (
  <HistoryRouter>
    <App />
  </HistoryRouter>
);

render(root, document.getElementById('root'));
// registerServiceWorker();
