interface UsePortalUrl {
  path?: string;
  searchParams?: {
    [key: string]: string;
  };
}

export const getPortalUrl = ({path, searchParams}: UsePortalUrl = {}) => {
  const port = window.location.port !== '80' ? `:${window.location.port}` : '';
  path = path ? `/${path}` : '';
  const url = new URL(
    `${window.location.protocol}//${window.location.hostname}${port}${path}`,
  );
  if (searchParams) {
    Object.entries(searchParams).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  }
  return url.toString();
};

export const usePortalUrl = ({path, searchParams}: UsePortalUrl = {}) => {
  return getPortalUrl({path, searchParams});
};
