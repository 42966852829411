/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useContext} from 'react';

import {ApolloError} from '@apollo/client';
import {
  ObjectType,
  jsonPointerResolver,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';
import {useMachine} from '@xstate/react';
import AppServicesContext from 'app/services/AppServicesContext';
import {
  actionVoter,
  baseSchema,
  validator,
} from 'common/global/entities/search/search';
import * as R from 'ramda';
import {Address} from 'typeDefs/types';
import getFetchDataResult from 'utils/apollo/getFetchDataResult';
import searchMachineActions, {
  MachineActions,
} from 'utils/machines/searchMachineActions';
import searchMachineGuards from 'utils/machines/searchMachineGuards';
import {mapBoxSuggestionToAddress} from 'utils/stores/addresses';
import getFormStore from 'utils/stores/getFormStore';
import {FormEventObject, SearchStoreMachineContext} from 'utils/stores/types';
import {assign} from 'xstate';

import {DSP_FormContextProps} from '../../FormProvider';

import searchMachine from './addressSearchMachine';
import {getSuggestedResults} from './getSuggestedResults';
import {MapBoxSuggestion, SuggestedResultsOptions} from './types';
export interface AddressSearchMachineProps {
  fieldRef: string;
  formService: DSP_FormContextProps['service'];
  userId: number;
  type: 'addressObject' | 'addressText' | 'freeText';
  options?: SuggestedResultsOptions;
}

export default function useAddressSearchMachine({
  fieldRef,
  formService,
  userId,
  type,
  options,
}: AddressSearchMachineProps) {
  const appServices = useContext(AppServicesContext);
  const container = useMachine(searchMachine, {
    context: R.mergeRight(getFormStore(baseSchema, validator), {
      props: {},
      data: [],
      fieldRef,
      onGenerateSASTokenFunc: async () => undefined,
      storeFieldValue: undefined,
      actors: {},
      searchTerm: '',
      storeFieldSchema: baseSchema,
      searchResultToString: (searchResult?: Address | null) => {
        if (searchResult && R.is(Object, searchResult)) {
          return R.trim(
            R.join(' ', [
              searchResult.line1 + ',',
              searchResult.locality,
              searchResult.state,
              searchResult.postcode,
            ]),
          );
        }
        return '';
      },
    }) as SearchStoreMachineContext,
    guards: R.mergeRight(searchMachineGuards(), {
      //bypass validation currently incompatible with stringValue searches and not required
      //todo NHVRREP-33156 may be removed
      isFormValueChanged: () => {
        return true;
      },
      isFreeTextMachine: () => {
        return type === 'freeText';
      },
    }),
    actions: R.mergeRight(
      searchMachineActions(baseSchema, validator, actionVoter, appServices),
      {
        onFormValueChange: assign({
          record: (context, event) => {
            const fieldValue = event.value as ObjectType;
            if (type === 'addressObject') {
              if (
                context.searchResultToString(fieldValue) !==
                context.record.search
              ) {
                return R.assoc(
                  'search',
                  context.searchResultToString(fieldValue),
                  context.record,
                );
              }
            } else {
              if (fieldValue !== context.record.search) {
                return R.assoc('search', fieldValue, context.record);
              }
            }
            return context.record;
          },
          props: (context, event) => {
            return R.assoc('overrideTouched', event.flag, context.props);
          },
          storeFieldValue: (_context, event) => {
            return event.value;
          },
          storeFieldSchema: (context, event) => {
            if (R.isNil(event.schema)) return context.storeFieldSchema;
            return event.schema;
          },
          storeFieldSchema2: (context, event) => {
            if (R.isNil(event.schema2)) return context.storeFieldSchema2;
            return event.schema2;
          },
        }),
        setParentValue: context => {
          const search = context.record.search as string;
          const sendBatch: FormEventObject[] = [
            {
              type: 'CHANGEBLUR',
              fieldRef,
              value: search,
            },
          ];
          formService.send(sendBatch);
        },
        onSearchSuccess: assign({
          data: (_context, event) => {
            return getFetchDataResult(event.data) as object[];
          },
          props: context => R.assoc('apiError', undefined, context.props),
        }),
        onReset: () => {
          const sendBatch: FormEventObject[] = [
            {
              type: 'CHANGEBLUR',
              fieldRef,
              value: '',
            },
          ];
          formService.send(sendBatch);
        },
        onSelect: (context, event) => {
          const locationResult = event?.value as MapBoxSuggestion;
          let value: Address | string =
            mapBoxSuggestionToAddress(locationResult);
          if (type !== 'addressObject')
            value = context.searchResultToString(value);
          const sendBatch: FormEventObject[] = [
            {
              type: 'CHANGEBLUR',
              fieldRef,
              value,
            },
          ];
          formService.send(sendBatch);
        },
        onLookupPlaceError: assign({
          props: (context, event) =>
            R.assoc('apiError', event.data as ApolloError, context.props),
        }),
      } as MachineActions,
    ),
    services: {
      apiSearch: async context => {
        const search = context.record.search as string;
        try {
          const results = await getSuggestedResults(search, userId, options);
          const {
            data: {suggestions},
          } = results;

          return {
            type: '',
            fieldRef: '#/search',
            data: {data: suggestions},
          };
        } catch (e) {
          return {
            type: '',
            fieldRef: '#/search',
            data: {data: []},
          };
        }
      },
      formStoreStream: context => callback => {
        const subscription = formService.subscribe({
          next: emitted => {
            //if value changed then update
            const fieldValue = jsonPointerResolver(context.fieldRef)(
              emitted.context.record,
            );
            const fieldSchema = schemaJsonPointerResolver(context.fieldRef)(
              emitted.context.schema,
            );
            const fieldSchema2 = schemaJsonPointerResolver(
              context.fieldRef + '/state',
            )(emitted.context.schema);
            callback({
              type: 'FORM_VALUE_CHANGE',
              fieldRef: context.fieldRef,
              value: fieldValue,
              schema: fieldSchema,
              schema2: fieldSchema2,
              flag: emitted.context.props.overrideTouched,
            });
          },
          error: () => {
            /* ... */
          },
          complete: () => {
            /* ... */
          },
        });
        return () => subscription.unsubscribe();
      },
    },
    delays: {
      DEBOUNCE_INPUT_DELAY: () => 500,
    },
  });
  return container;
}
