import Assignment from '@mui/icons-material/Assignment';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/:accreditationId/audits',
  component: lazy(() => import(/* webpackChunkName: "Audits" */ './Audits')),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Audits',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'AccreditationAuditHistoryWithRelations.findCustomer',
    policies: [
      {role: 'customerAccreditationRead'},
      {role: 'operationsAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation Management',
      to: '..',
    },
  ],
};
