import {lazy} from 'react';
import {RouteConfig} from 'app/router/types';
import SubmittedAccreditationApplication from '../Applications/Submitted';
import SubmittedAccreditationOrderApplication from '../Applications/Submitted/index-orders';
import {AccreditationApplication} from './Application';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import Assignment from '@mui/icons-material/Assignment';
import {AccreditationApplicationOrder} from './Shared/components/Wizards/Orders';

const AccreditationApplications = {
  path: '/:accreditationId/applications',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationApplications" */ './AccreditationApplications'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Applications',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.findCustomer',
    policies: [
      {role: 'customerAccreditationRead'},
      {role: 'operationsAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation Management',
      to: '..',
    },
  ],
} as RouteConfig;

export default [
  AccreditationApplications,
  AccreditationApplication,
  AccreditationApplicationOrder,
  SubmittedAccreditationApplication,
  SubmittedAccreditationOrderApplication,
];
