import searchMachine, {SearchMachineConfig} from 'utils/machines/searchMachine';

const machine = searchMachine({
  id: 'addressSearch',
  states: {
    userInput: {
      states: {},
      on: {
        SELECT: [
          {
            actions: ['onSelect'],
          },
        ],
        CHANGE: [
          {
            cond: 'isFreeTextMachine',
            actions: ['onChange', 'setParentValue'],
            target: 'userInput.debouncing',
          },
          {
            target: 'userInput.debouncing',
            actions: 'onChange',
          },
        ],
        RESET: {
          target: 'userInput',
          actions: 'onReset',
        },
      },
    },
  },
} as unknown as SearchMachineConfig);
export default machine;
