import {lazy} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';

export default {
  path: '/user-profile/security-details/:id',
  component: lazy(
    () => import(/* webpackChunkName: "Profile" */ './SecurityDetails'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'User Profile',
  menuIcon: HomeIcon,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.AUTHENTICATED] as AuthorisationPolicy[],
  },
  sections: [],
};
