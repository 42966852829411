import React from 'react';

import {
  DSL_ButtonBar,
  DSL_OutlinedButton,
  DSL_ButtonBarGroup,
} from 'components/DesignSystem/Library';

import {DSP_SubmitButton} from '../../../SubmitButton';

export interface DSP_SaveOrCancelButtonStackViewProps {
  onSaveClick: React.MouseEventHandler<HTMLButtonElement>;
  onCancelClick: () => void;
  disabled?: boolean;
}

export const DSP_SaveOrCancelButtonStackView = ({
  onSaveClick,
  onCancelClick,
  disabled,
}: DSP_SaveOrCancelButtonStackViewProps) => {
  return (
    <DSL_ButtonBar>
      <DSL_ButtonBarGroup>
        <DSL_OutlinedButton onClick={onCancelClick}>Cancel</DSL_OutlinedButton>
        <DSP_SubmitButton disabled={disabled} onClick={onSaveClick}>
          Save
        </DSP_SubmitButton>
      </DSL_ButtonBarGroup>
    </DSL_ButtonBar>
  );
};

DSP_SaveOrCancelButtonStackView.displayName = 'DSP_SaveOrCancelButtonStackView';

export {
  //
  /** @deprecated use DSP_SaveOrCancelButtonStackViewProps instead*/
  DSP_SaveOrCancelButtonStackViewProps as SaveOrCancelButtonStackViewProps, //
  /** @deprecated use DSP_SaveOrCancelButtonStackView instead*/
  DSP_SaveOrCancelButtonStackView as SaveOrCancelButtonStackView,
};
