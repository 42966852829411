import {AssignmentOutlined} from '@mui/icons-material';
import {AccreditationRoutes} from 'containers-accreditation/index';
import AuditorRoutes from 'containers-accreditation/Auditors';
import ManageAccreditationRoutes from 'containers-accreditation/Manage';
import Auditors from './Auditors';
import Applications from './Applications';
import LandingSwitch from './LandingSwitch';
import content from 'containers-accreditation/content';

export default {
  content,
  routes: [
    LandingSwitch,
    Applications,
    Auditors,
    ...ManageAccreditationRoutes,
    ...AccreditationRoutes,
    ...AuditorRoutes,
  ],
  menuTitle: 'WA Heavy Vehicle Accreditation',
  menuDescription: 'Western Australia Heavy Vehicle Accreditation',
  path: '/accreditation-wahva',
  showChildRoutesInMenu: true,
  menuIcon: AssignmentOutlined,
};
