import {JSONSchemaRecord} from '@regulatory-platform/common-utils/dist';
import {useUserProfile} from 'app/services/useUserProfile';
import * as R from 'ramda';
import getErrorMessage from 'utils/schema/getErrorMessage';
import isError from 'utils/schema/isError';

import {useFormService} from '../FormProvider';

import {MapBoxSuggestion} from './api/types';
import useAddressSearchMachine, {
  AddressSearchMachineProps,
} from './api/useAddressSearchMachine';

export const useAddressSearch = (
  fieldRef: string,
  searchType: AddressSearchMachineProps['type'],
  searchOptions: AddressSearchMachineProps['options'],
) => {
  const service = useFormService();
  const user = useUserProfile();
  const [searchState, searchSend] = useAddressSearchMachine({
    fieldRef,
    formService: service,
    userId: user.id,
    type: searchType,
    options: searchOptions,
  });

  const stateFieldSchema = searchState.context
    .storeFieldSchema as JSONSchemaRecord;

  let isError2 = false;
  const storeFieldSchema2 = searchState.context
    .storeFieldSchema2 as JSONSchemaRecord;
  if (!R.isNil(storeFieldSchema2)) {
    isError2 =
      storeFieldSchema2['x-valid']?.valid === false &&
      (storeFieldSchema2['x-valid']?.touched ||
        searchState.context.props?.overrideTouched === true);
  }
  const isValidError =
    isError(
      stateFieldSchema,
      searchState.context.props.overrideTouched === true,
    ) || isError2;
  const errorMessage = isValidError
    ? getErrorMessage(stateFieldSchema)
    : isError2
    ? getErrorMessage(storeFieldSchema2)
    : undefined;
  const label = stateFieldSchema.title as string;
  const isLoading =
    searchState.matches('userInput.debouncing') ||
    searchState.matches('userInput.searching');

  const options = searchState.context.data as MapBoxSuggestion[];
  const search = (
    searchState.context.record.search === ',' ||
    searchState.context.record.search === 'undefined,'
      ? ''
      : searchState.context.record.search
  ) as string;
  const onChange = (
    _event: React.ChangeEvent<{}>,
    newValue: MapBoxSuggestion | null,
  ): void => {
    searchSend('SELECT', {fieldRef: '#/search', value: newValue});
  };
  const onInputChange = (
    _event: React.ChangeEvent<{}>,
    searchTerm: string,
    reason: 'input' | 'reset' | 'clear' | 'create-option' | 'remove-option',
  ): void => {
    if (reason === 'reset') return; //programmatic change
    if (reason === 'clear' || (reason === 'input' && R.isEmpty(searchTerm))) {
      searchSend('RESET', {
        fieldRef: '#/search',
        value: searchTerm,
      });
      return;
    }
    searchSend('CHANGE', {fieldRef: '#/search', value: searchTerm});
  };
  return {
    isLoading,
    options,
    isValidError,
    searchSend,
    search,
    onChange,
    onInputChange,
    errorMessage,
    label,
  };
};
