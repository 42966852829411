import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/:accreditationId/applications/:id/submitted',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationApplicationSubmitted" */ './AccreditationApplicationSubmitted'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Application Submitted',
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.workflowById:SUBMIT',
    policies: [{role: 'customerAccreditationUser'}] as AuthorisationPolicy[],
  },
  sections: [],
};
