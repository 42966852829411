import React from 'react';

import {
  GridItem,
  UseGridItemStyles,
  FormattedNumberInput,
  FormattedNumberInputProps,
} from 'components/DesignSystem/Library';

import {useNumberField, DSP_UseNumberField} from './useNumberField';

export type DSP_NumberFieldProps = DSP_UseNumberField &
  UseGridItemStyles &
  Partial<FormattedNumberInputProps>;
export const DSP_NumberField = ({
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}: DSP_NumberFieldProps) => {
  const fieldProps = useNumberField(props);
  return (
    <GridItem span={span} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <FormattedNumberInput {...fieldProps} />
    </GridItem>
  );
};

export {
  //
  /** @deprecated use DSP_NumberFieldProps instead*/
  DSP_NumberFieldProps as NumberFieldProps, //
  /** @deprecated use DSP_NumberField instead*/
  DSP_NumberField as NumberField,
};
