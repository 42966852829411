import {SelectChangeEvent} from '@mui/material/Select';

import {SelectInputProps} from 'components/DesignSystem/Library';

import {useFormService} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks/useFormFieldPropsSelector';
import {getArrayFieldProps} from '../utils/getArrayFieldProps';

import {DSP_MultiSelectFieldProps} from './MultiSelectField.Connector';

export function useMultiSelectField({
  fieldRef,
  options,
  onBlurEvent,
  onChangeEvent,
  recordsLoaderRef,
  label,
  hideLabel,
  includeOptionalSuffix,
  ...restProps
}: DSP_MultiSelectFieldProps): SelectInputProps {
  const service = useFormService();

  const stateDerivedProps = useFormFieldPropsSelector(
    fieldRef,
    service,
    state => {
      const fieldProps = getArrayFieldProps<true, false, false>({
        fieldRef,
        state,
        items: options,
        label,
        multiple: true,
        includeOptionalSuffix,
        hideLabel,
      });

      return {
        ...fieldProps,
        onChange: (e: SelectChangeEvent): void => {
          const value = e?.target?.value || [];
          service.send({
            type: 'CHANGEBLUR',
            fieldRef,
            value,
          });
          onChangeEvent?.();
        },
        onBlur: (): void => {
          service.send({type: 'BLUR', fieldRef});
          onBlurEvent?.();
        },
        onOpen: (): void => {
          if (recordsLoaderRef) {
            service.send('TRIGGER_ACTOR_LOAD', {
              fieldRef: recordsLoaderRef,
            });
          }
        },
      } as SelectInputProps;
    },
  );
  return {
    ...stateDerivedProps,
    ...restProps,
  };
}
