import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accreditationSchemes/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationScheme" */ './AccreditationScheme'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage accreditation scheme',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccreditationSchemeWithRelations.findById',
    policies: [
      {
        role: 'operationsAccreditationSupervisor',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation Schemes',
      to: '..',
    },
  ],
};
