import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/auditors/create',
  component: lazy(
    () => import(/* webpackChunkName: "CreateAuditor" */ './CreateAuditor'),
  ),
  visibleInMenu: false,
  menuIcon: Today,
  menuTitle: 'Auditor',
  hideInProduction: false,
  authorisation: {
    apiKey: 'Auditor.create',
    policies: [{role: 'operationsAccreditationUser'}] as AuthorisationPolicy[],
  },
  sections: [],
};
