import React, {ReactNode} from 'react';

import {DSL_ButtonBarGroup} from '../../../Buttons/ButtonBar';
import {
  DSL_ContainedButton,
  DSL_ContainedButtonProps,
} from '../../../Buttons/ContainedButton';
import {DSL_TextButton} from '../../../Buttons/TextButton';
import {DSL_Dialog, DSL_DialogProps} from '../Dialog';
import {DSL_DialogActions} from '../DialogActions';
import {DSL_DialogContent} from '../DialogContent';
import {DSL_DialogTitle} from '../DialogTitle';

export interface DSL_ConfirmDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationMessage: ReactNode;
  confirmationDialogTitle?: ReactNode;
  confirmationButtonLabel: string;
  confirmationButtonColor?: DSL_ContainedButtonProps['color'];
  confirmationButtonEndIcon?: DSL_ContainedButtonProps['endIcon'];
  loading?: boolean;
  size?: DSL_DialogProps['size'];
  hasErrorIcon?: boolean;
  cancelButtonLabel?: string;
}

export const DSL_ConfirmDialog = ({
  open,
  onCancel,
  onConfirm,
  confirmationMessage,
  confirmationDialogTitle,
  confirmationButtonLabel,
  confirmationButtonEndIcon,
  confirmationButtonColor = 'error',
  loading,
  size,
  hasErrorIcon,
  cancelButtonLabel = 'Cancel',
}: DSL_ConfirmDialogProps) => {
  return (
    <DSL_Dialog
      open={open}
      onClose={onCancel}
      aria-label={'confirm-dialog'}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-content"
      size={size}
    >
      <DSL_DialogTitle
        onClose={onCancel}
        title={confirmationDialogTitle ?? confirmationButtonLabel}
        id="confirm-dialog-title"
        hasErrorIcon={hasErrorIcon}
      />
      <DSL_DialogContent id="confirm-dialog-content">
        {confirmationMessage}
      </DSL_DialogContent>
      <DSL_DialogActions>
        <DSL_ButtonBarGroup>
          <DSL_TextButton onClick={onCancel}>
            {cancelButtonLabel}
          </DSL_TextButton>

          <DSL_ContainedButton
            color={confirmationButtonColor}
            onClick={onConfirm}
            loading={loading}
            disabled={loading}
            endIcon={confirmationButtonEndIcon}
          >
            {confirmationButtonLabel}
          </DSL_ContainedButton>
        </DSL_ButtonBarGroup>
      </DSL_DialogActions>
    </DSL_Dialog>
  );
};

DSL_ConfirmDialog.displayName = 'DSL_ConfirmDialog';

export {
  //
  /** @deprecated use DSL_ConfirmDialogProps instead*/
  DSL_ConfirmDialogProps as ConfirmDialogProps, //
  /** @deprecated use DSL_ConfirmDialog instead*/
  DSL_ConfirmDialog as ConfirmDialog,
};
