import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/:accreditationId/vehicles',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationVehicles" */ './AccreditationVehicles'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Accreditation Vehicles',
  menuIcon: Today,
  authorisation: {
    apiKey: 'AccreditationVehicleMapping.findCustomer',
    policies: [
      {role: 'customerAccreditationRead'},
      {role: 'operationsAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation Management',
      to: '..',
    },
  ],
};
