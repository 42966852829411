import {SettingsOutlined} from '@mui/icons-material';
import {AccreditationSchemesRoutes} from './AccreditationScheme';
import AuditorCreate from '../containers-accreditation/Auditors/CreateAuditor';
import AuditorDetails from '../containers-accreditation/Auditors/AuditorDetails';
import content from './content';

export default {
  routes: [...AccreditationSchemesRoutes, AuditorCreate, AuditorDetails],
  menuTitle: 'Accreditation Administration',
  menuDescription: 'Administration of accreditation schemes',
  path: '/accreditation-admin',
  showChildRoutesInMenu: true,
  menuIcon: SettingsOutlined,
  content,
};
