import React from 'react';

import {SearchControlProps} from 'utils/stores/types';

import {DSL_BaseInputProps} from 'components/DesignSystem/Library';

import {DSP_GetGenericFieldProps} from '../utils/getGenericFieldProps';

import {DSP_AddressSearchView} from './AddressSearch.View';
import {AddressSearchMachineProps} from './api/useAddressSearchMachine';
import {useAddressSearch} from './useAddressSearch';

export interface DSP_AddressSearchProps
  extends Omit<SearchControlProps, 'service'>,
    Omit<DSP_GetGenericFieldProps, 'fieldRef' | 'state'>,
    Pick<DSL_BaseInputProps, 'placeholder' | 'label'> {
  disabled?: boolean;
  fieldRef: string;
  searchLabel?: string;
  validationErrorMessage?: string;
  searchType?: AddressSearchMachineProps['type'];
  searchOptions?: AddressSearchMachineProps['options'];
}

export const DSP_AddressSearch: React.FC<DSP_AddressSearchProps> = ({
  fieldRef,
  disabled,
  searchType = 'addressObject',
  searchLabel,
  searchOptions = {},
}: DSP_AddressSearchProps) => {
  const {
    options,
    isLoading,
    isValidError,
    search,
    onChange,
    onInputChange,
    errorMessage,
    label,
  } = useAddressSearch(fieldRef, searchType, searchOptions);
  return (
    <DSP_AddressSearchView
      disabled={disabled}
      label={searchLabel || label}
      helperText={errorMessage}
      options={options}
      inputValue={search}
      loading={isLoading}
      error={isValidError}
      onChange={onChange}
      onInputChange={onInputChange}
    />
  );
};

DSP_AddressSearch.displayName = 'DSP_AddressSearch';
