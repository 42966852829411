import accountsContainer from 'containers-accounts/index';
import pbsContainer from 'containers-pbs/index';
import ssoContainer from 'containers-sso/index';
import adminContainer from 'containers-admin/index';
import vehicleContainer from 'containers-vehicle/index';
import complianceContainer from 'containers-compliance/index';
import networkContainer from 'containers-networks/index';
import accessContainer from 'containers-access/index';
import accreditationContainer from 'containers-accreditation/index';
import accreditationNHVASContainer from 'containers-accreditation-nhvas/index';
import accreditationWAHVAContainer from 'containers-accreditation-wahva/index';
import accreditationAdminContainer from 'containers-accreditation-admin/index';
import informationContainer from 'containers-information/index';
import mapContainer from 'containers-map/index';
import permitsContainer from 'containers-permits/index';
import {ContainerConfig} from './types';

export const authenticatedContainers: ContainerConfig[] = [
  ssoContainer,
  accessContainer,
  networkContainer,
  mapContainer,
  accreditationContainer,
  accreditationNHVASContainer,
  accreditationWAHVAContainer,
  accreditationAdminContainer,
  complianceContainer,
  vehicleContainer,
  pbsContainer,
  accountsContainer,
  adminContainer,
  informationContainer,
  permitsContainer,
];
