import React, {ReactNode} from 'react';

import {DSL_Box} from '../../Layout/Box';
import {DSL_CardContent} from '../../Layout/Card/CardContent';
import {
  DSL_OutlinedCard,
  DSL_OutlinedCardProps,
} from '../../Layout/Card/OutlinedCard';
import {DSL_Divider} from '../../Layout/Divider';
import {DSL_FlexColumn} from '../../Layout/FlexColumn';
import {DSL_Body1} from '../../Typography/Body1';
import {DSL_Heading4} from '../../Typography/Heading4';

export interface DSL_FormCardProps extends DSL_OutlinedCardProps {
  heading?: ReactNode;
  subHeading?: ReactNode;
}
export const DSL_FormCard = ({
  heading,
  subHeading,
  children,
  ...props
}: DSL_FormCardProps) => {
  return (
    <DSL_OutlinedCard {...props}>
      <DSL_CardContent spacing={'NONE'} padding={'MD'}>
        <DSL_FlexColumn gap={4}>
          {heading && (
            <>
              <DSL_FlexColumn gap={1}>
                <DSL_Heading4 gutterBottom={false}>{heading}</DSL_Heading4>
                {subHeading && <DSL_Body1>{subHeading}</DSL_Body1>}
              </DSL_FlexColumn>
              <DSL_Divider />
            </>
          )}
          <DSL_Box>{children}</DSL_Box>
        </DSL_FlexColumn>
      </DSL_CardContent>
    </DSL_OutlinedCard>
  );
};
