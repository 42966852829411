import {Address} from 'typeDefs/types';
import * as R from 'ramda';
import {MapBoxSuggestion} from 'components/DesignSystem/Portal/Forms/AddressSearch/api/types';

export const getAddress = (address: Address | null | undefined): string => {
  const addressArray = [
    R.defaultTo('', address?.line1),
    R.defaultTo('', address?.line2),
    R.defaultTo('', address?.locality),
    R.defaultTo('', address?.state),
    R.defaultTo('', address?.postcode),
  ];
  return R.join(
    ', ',
    R.filter(a => {
      return !R.isEmpty(a);
    }, addressArray),
  );
};

const getAddressObject = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): {
  home: string;
  postalCode: string;
  street: string;
  region: string;
  city: string;
  country: string;
} => {
  const addressComponent = {
    home: ['street_number'],
    street: ['street_address', 'route'],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    postalCode: ['postal_code'],
    country: ['country'],
  };

  const address = {
    home: '',
    street: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
  };
  addressComponents.forEach(component => {
    if (
      R.any(
        componentType => R.includes(componentType, addressComponent.home),
        component.types,
      )
    ) {
      address.home = component.long_name;
    }
    if (
      R.any(
        componentType => R.includes(componentType, addressComponent.street),
        component.types,
      )
    ) {
      address.street = component.long_name;
    }
    if (
      R.any(
        componentType => R.includes(componentType, addressComponent.city),
        component.types,
      )
    ) {
      address.city = component.long_name;
    }
    if (
      R.any(
        componentType => R.includes(componentType, addressComponent.region),
        component.types,
      )
    ) {
      address.region = component.long_name;
    }
    if (
      R.any(
        componentType => R.includes(componentType, addressComponent.postalCode),
        component.types,
      )
    ) {
      address.postalCode = component.long_name;
    }
    if (
      R.any(
        componentType => R.includes(componentType, addressComponent.country),
        component.types,
      )
    ) {
      address.country = component.long_name;
    }
  });
  return address;
};

export const normaliseState = (state: string): string => {
  if (R.isNil(state) || R.isEmpty(state)) {
    return state;
  }
  const lowercaseState = R.toLower(state);
  if (lowercaseState.indexOf('q') === 0) {
    return 'QLD';
  }
  if (lowercaseState.indexOf('a') === 0) {
    return 'ACT';
  }
  if (lowercaseState.indexOf('v') === 0) {
    return 'VIC';
  }
  if (lowercaseState.indexOf('t') === 0) {
    return 'TAS';
  }
  if (lowercaseState.indexOf('s') === 0) {
    return 'SA';
  }
  if (lowercaseState.indexOf('w') === 0) {
    return 'WA';
  }
  if (lowercaseState.indexOf('ne') === 0) {
    return 'NSW';
  }
  if (lowercaseState.indexOf('no') === 0) {
    return 'NT';
  }
  return '';
};

export const processGeocodeResult = (
  currentAddress?: Address | null,
  item?: google.maps.GeocoderResult,
): Address | null | undefined => {
  //google api returns 200 response for subscription errors and result.results = {"error_message": "...
  if (R.isNil(item)) {
    return currentAddress;
  }
  const processedAddress = getAddressObject(item.address_components);
  return R.mergeRight(R.defaultTo({}, currentAddress), {
    line1:
      (processedAddress.home !== '' ? processedAddress.home + ' ' : '') +
      processedAddress.street,
    locality: processedAddress.city,
    state: normaliseState(processedAddress.region),
    postcode: processedAddress.postalCode,
    country: processedAddress.country,
  });
};

export const mapBoxSuggestionToAddress = (
  suggestion: MapBoxSuggestion,
): Address => ({
  line1: suggestion.context.address?.name,
  locality: suggestion.context.locality?.name || suggestion.context.place?.name,
  state: normaliseState(suggestion.context.region?.name),
  postcode: suggestion.context.postcode?.name,
  country: suggestion.context.country?.name,
});
