import {AssignmentOutlined} from '@mui/icons-material';
import AfmApplication from 'containers-accreditation/AfmApplication';
import ApplicationRoutes from 'containers-accreditation/Applications';
import NewAfmApplication from 'containers-accreditation/AfmApplication/NewAfmApplication';
import AfmApplicationGateway from 'containers-accreditation/AfmApplication/AfmApplicationGateway';
import AfmApplicationSummary from 'containers-accreditation/AfmApplication/AfmApplicationSummary';
import AfmApplicationOperationsManualSummary from 'containers-accreditation/AfmApplication/AfmApplicationOperationManualSummary';
import OperationManual from 'containers-accreditation/OperationManual';
import NewOperationManual from 'containers-accreditation/OperationManual/NewOperationManual';
import OperationManualGateway from 'containers-accreditation/OperationManual/OperationManualGateway';
import AfmApplicationOperationManualGateway from 'containers-accreditation/AfmApplication/AfmApplicationOperationManualGateway';
import content from './content';
import {RouteConfig} from '../app/router/types';
import Landing from './Landing';
import Sandbox from './Sandbox';
import ComplianceHistory from './ComplianceHistory';
import Vehicles from './Vehicles';
import VehicleDefectsAccreditationOperations from './VehicleDefects/v1/VehicleAccreditationOperations';
import VehicleDefectsAccreditation from './VehicleDefects/v1';
import VehicleDefects from './VehicleDefects';
import Drivers from './Drivers';
import Orders from './Orders';
import Employees from './Employees';
import Audits from './Audits';

export const AccreditationRoutes = [
  Landing,
  Sandbox,
  ...ApplicationRoutes,
  Vehicles,
  Drivers,
  VehicleDefects,
  Orders,
  Employees,
  Audits,
  ComplianceHistory,
] as RouteConfig[];

export default {
  content,
  path: '/accreditation',
  menuTitle: 'Accreditation',
  menuDescription: 'Track and maintain your accreditation status effortlessly.',
  showChildRoutesInMenu: true,
  menuIcon: AssignmentOutlined,
  routes: [
    AfmApplication,
    AfmApplicationGateway,
    NewAfmApplication,
    VehicleDefectsAccreditation,
    VehicleDefectsAccreditationOperations,
    AfmApplicationSummary,
    AfmApplicationOperationsManualSummary,
    OperationManual,
    NewOperationManual,
    OperationManualGateway,
    AfmApplicationOperationManualGateway,
  ],
};
