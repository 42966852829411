import React from 'react';
import {useLayoutEffect, useState} from 'react';
import {
  Router,
  BrowserRouterProps as NativeBrowserRouterProps,
} from 'react-router-dom';
import {createBrowserHistory} from 'history';

// by injecting a custom history object into our router we can access this object from outside the react context, such
// as in the auth servicd. This allows us to navigate the user to a different page after a successful login.
export const history = createBrowserHistory({window});

export const HistoryRouter = (props: NativeBrowserRouterProps) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), []);
  return (
    <Router
      {...props}
      location={state.location}
      action={state.action}
      navigator={history}
    />
  );
};
