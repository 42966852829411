import React from 'react';

import * as R from 'ramda';

import {
  DSL_BaseAutocompleteInput,
  DSL_BaseAutocompleteInputProps,
  DSL_Body2,
  DSL_FlexRow,
  DSL_FormControl,
  DSL_LocationOnIcon,
} from 'components/DesignSystem/Library';

import {MapBoxSuggestion} from './api/types';
export type DSL_AddressSearchProps = Omit<
  DSL_BaseAutocompleteInputProps<MapBoxSuggestion, false, false, false>,
  | 'getOptionLabel'
  | 'renderOption'
  | 'autoComplete'
  | 'includeInputInList'
  | 'filterSelectedOptions'
>;

export const DSP_AddressSearchView = (
  autoCompleteProps: DSL_AddressSearchProps,
) => {
  return (
    <DSL_FormControl>
      <DSL_BaseAutocompleteInput
        getOptionLabel={(searchResult: MapBoxSuggestion): string => {
          return R.defaultTo('', searchResult?.full_address);
        }}
        autoComplete
        includeInputInList
        filterSelectedOptions
        renderOption={(props, option: MapBoxSuggestion): React.ReactNode => {
          return (
            <li {...props}>
              <DSL_FlexRow alignItems="center">
                <DSL_LocationOnIcon />
                <DSL_Body2 flex={1}>{option.full_address}</DSL_Body2>
              </DSL_FlexRow>
            </li>
          );
        }}
        {...autoCompleteProps}
      />
    </DSL_FormControl>
  );
};

DSP_AddressSearchView.displayName = 'AddressSearchView';
