import {useCallback} from 'react';
import {SSOLoginStorageKeys} from 'utils/storage';
import {useIsIframeMode} from 'app/services/AppServicesContext';
import {usePortalUrl} from 'components/DesignSystem/Portal';
import {PortalV1Urls} from '../../utils/global';
import {getHashParams} from '../../components/DesignSystem/Library';

const {client_id, instance_id, b2c_subdomain, mfa_type} =
  window.globalEnvVariables?.connectors?.b2c ?? {};

const isMfa = mfa_type !== 'NONE';

interface UseB2cNavigate {
  action: EntraAction;
  returnUrl?: string;
  additionalSearchParams?: {
    [key: string]: string;
  };
}

export const EntraClientNameMap = {
  SIGNIN_SIGNUP: isMfa
    ? 'B2C_1A_SIGNUP_SIGNIN_PHONEOREMAILMFA'
    : 'B2C_1A_SIGNUP_SIGNIN_NO_MFA',
  SIGNUP: 'B2C_1A_SIGNUP_PHONEOREMAILMFA',
  PASSWORDRESET: 'B2C_1A_PASSWORDRESET_EMAILORPHONE_MFA',
  CHANGE_EMAIL: 'B2C_1A_CHANGE_SIGNINEMAIL_MFA',
  CHANGE_PHONENUMBER: 'B2C_1A_CHANGE_PHONENUMBER_MFA',
} as const;

export type EntraAction = keyof typeof EntraClientNameMap;

export const getActionFromEntraClientName = (clientName: string) => {
  return Object.keys(EntraClientNameMap).find(
    key => EntraClientNameMap[key as EntraAction] === clientName,
  ) as EntraAction;
};

type AuthResponse = 'success' | 'error' | 'cancelled';

const conditionallySetSearchParam = (
  searchParams: URLSearchParams,
  key: string,
  value?: string | null,
) => {
  if (value) {
    searchParams.set(key, value);
  }
  return searchParams;
};

export const getAuthResponseFromB2cReturnUrl = (): {
  authResponse: AuthResponse;
  authError?: string;
} => {
  const searchParams = new URLSearchParams(window.location.search);
  const hashParams = getHashParams();

  switch (true) {
    case searchParams.get('auth-response') === 'cancel':
      return {authResponse: 'cancelled'};
    case hashParams.get('error') === 'access_denied' &&
      hashParams.get('error_description')?.includes('The user has cancelled'):
      return {authResponse: 'cancelled'};
    case !!hashParams.get('error') || !!hashParams.get('error_description'):
      return {
        authResponse: 'error',
        authError:
          hashParams.get('error_description') ??
          hashParams.get('error') ??
          undefined,
      };
    default:
      return {authResponse: 'success'};
  }
};

export const processB2cReturnUrl = (returnUrl: string) => {
  const searchParams = new URLSearchParams(window.location.search);

  const {authResponse, authError} = getAuthResponseFromB2cReturnUrl();

  const portalV1Urls = Object.values(PortalV1Urls);

  // we pass the response and the action back to the calling page so it
  // knows if the action was successful or not.
  const action = searchParams.get('auth-action');

  // support portal v1 urls
  const isHashUrl = returnUrl?.includes('#');
  const processedUrl = isHashUrl ? returnUrl.replace('#', '?') : returnUrl;
  const url = new URL(processedUrl);

  const isPortalV1Url = portalV1Urls.includes(
    url.origin as (typeof portalV1Urls)[number],
  );
  const portalAction = action ? getActionFromEntraClientName(action) : null;

  switch (true) {
    // for a successful portal v1 auth flow, we just pass the url back, the return_url
    // is stored on the v1 side in local storage
    case isPortalV1Url && authResponse === 'success':
      break;
    // for a cancelled/error portal v1 auth flow, we pass the error back to an authError page
    // which will process the return url
    case isPortalV1Url && authResponse !== 'success':
      url.searchParams.set('page', 'authError');
      url.searchParams.set('auth-response', authResponse);
      conditionallySetSearchParam(
        url.searchParams,
        'auth-action',
        portalAction,
      );
      conditionallySetSearchParam(url.searchParams, 'auth-error', authError);
      break;
    // for portal v2 urls we always pass through the action and the response
    default:
      url.searchParams.set('auth-response', authResponse);
      conditionallySetSearchParam(
        url.searchParams,
        'auth-action',
        portalAction,
      );
      conditionallySetSearchParam(url.searchParams, 'auth-error', authError);
  }

  return isHashUrl ? url.toString().replace('?', '#') : url.toString();
};

export const useB2cNavigate = ({
  action,
  returnUrl,
  additionalSearchParams,
}: UseB2cNavigate) => {
  const redirect_uri = usePortalUrl({path: 'b2cLogin'});
  const iframeMode = useIsIframeMode();
  // when navigating to b2c, we break out of frames if they're present, then make the return url the calling frame url
  const targetWindow = iframeMode ? window.top ?? window : window;
  if (returnUrl === undefined) {
    returnUrl = targetWindow.location.href;
  }

  const customPolicy = EntraClientNameMap[action];

  return useCallback(() => {
    const searchParams = new URLSearchParams({
      p: customPolicy,
      client_id: client_id,
      nonce: 'defaultNonce',
      redirect_uri: redirect_uri,
      scope: 'openid',
      response_type: 'id_token',
      prompt: 'login',
      ...(additionalSearchParams ?? {}),
    });

    if (returnUrl) {
      sessionStorage.setItem(
        SSOLoginStorageKeys.RETURN_URL_PATH,
        returnUrl as string,
      );
    } else {
      sessionStorage.removeItem(SSOLoginStorageKeys.RETURN_URL_PATH);
    }

    const url = `https://${b2c_subdomain}.b2clogin.com/${instance_id}/oauth2/v2.0/authorize?${searchParams}`;

    targetWindow.location.href = url;
  }, [
    customPolicy,
    returnUrl,
    targetWindow,
    redirect_uri,
    additionalSearchParams,
    action,
  ]);
};
